/**
 * lapiAccess module provides functionalities to manage access controls for users based on their roles and settings.
 */
export const lapiAccess = {
    enabledOrdersByForeignWork(component){
        const $auth = component.$auth;
        const user = $auth.user();
        if(this.isAdmin(user)){return true;}
        return  user && user.data_branch_office && !!user.data_branch_office.extra_settings.find(item => {
            return item.type === 'ui.enabled_ofw'
        })
    },
    checksAccessModule(component, module) {
        const $auth = component.$auth;
        const user = $auth.user();
        if(this.isAdmin(user)){return true;}
        if(this.isAdmin(user)){return true;}
        const branchAccess = user && user.data_branch_office && !!user.data_branch_office.extra_settings.find(item => {
            return item.type === module
        })
        const userAccess = user && user.extra_settings.find(item => {
            return item.type === module
        })
        return branchAccess || userAccess;
    },
    callCenterAccess(component){
        const $auth = component.$auth;
        const user = $auth.user();
        if(this.isAdmin(user)){return true;}
        return user && ['35'].includes(user.active_branch_office.toString())
    },
    inBranch(component, branchsList){
        const $auth = component.$auth;
        const user = $auth.user();
        if(this.isAdmin(user)){return true;}
        return user && branchsList.includes(user.active_branch_office.toString())
    }
}