export default {
    namespaced: true,
    state:{
        patient: {},
        id: null,
        studies: [],
        showModalSelectPatient: false,
        date: new Date(),
    },
    mutations: {
        setPatient(state, val = {}){
            state.patient = val;
        },
        clearSalesCallCenter(state) {
            state.patient = {};
            state.id = null;
            state.date = new Date();
        },
        setId(state, val={}){
            state.id = val;
        },
        setStudies(state,val=[]){
            state.studies = val;
        },
        setShowModalSelectPatient(state, value){
            state.showModalSelectPatient = value;
        },
        setDate(state,val=null){
            state.date =new Date();
        }
    },
    actions: {
        setSalesCallCenter(context, salescallcenter){
            context.commit('setPatient',salescallcenter.patient);
            context.commit('setId',salescallcenter.id);
            context.commit('setStudies', salescallcenter.studies);
            context.commit('setDate',salescallcenter.date);
        },
        setPatient(context, val) {
            context.commit('setPatient', val);
        },
        setId(context, val){
            context.commit('setId', val);
        },
        setStudies(context, val){
          context.commit('setStudies', val);
        },
        clearSalesCallCenter(context) {
            context.commit('clearSalesCallCenter');
        },
        removePatient (context){
            context.commit('setPatient',{});
        },
        setShowModalSelectPatient({commit}, value) {
            commit('setShowModalSelectPatient', value)
        },
        setDate(context, val){
            context.commit('setDate',val);
        }
    },
    getters: {
        hasPatient: state => {
            return !!state.patient.code;
        },
        hasId: state => {
            return !!state.id;
        },
    }
}