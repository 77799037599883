import Vue from 'vue'
import VueRouter from 'vue-router'
import DefaultRouteView from "../components/DefaultRouteView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "default-layout" */ '../components/DefaultLayout.vue'),
        meta: {
            auth: true,
        },
        children: [
            {
                path: '',
                name: 'home',
                component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
            },
            {
                path: 'configure',
                name: 'configure',
                component: () => import(/* webpackChunkName: "configure" */ '../views/Configure.vue'),
            },
            {
                path: 'tools',
                component: DefaultRouteView,
                children:[
                    {
                        path: '',
                        name: 'tools',
                        component: () => import('../views/tools/Menu.vue'),
                    },
                    {
                        path:'equipment-communications',
                        name: 'equipment-communications',
                        component: () => import('../views/tools/Index.vue')
                    },
                ],
            },
            {
                path: 'reports',
                component: DefaultRouteView,
                children:[
                    {
                        path: '',
                        name: 'reports',
                        component: () => import('../views/report/Index.vue'),
                    },
                    {
                        path: "pending_orders",
                        name: "pending-orders",
                        component: () => import('../components/report/PendingOrder.vue')
                    },
                    {
                        path: "orders-day",
                        name: "orders-day",
                        component: () => import('../components/report/OrdersDay')
                    },
                    {
                        path: "task-monitor",
                        name: "task-monitor",
                        component: () => import('../components/report/TaskMonitor')
                    },
                    {
                        path: "task-monitor-xray",
                        name: "task-monitor-xray",
                        component: () => import('../components/report/TaskMonitorXray')
                    },
                    {
                        path: "orders-report",
                        name: "orders-report",
                        component: () => import('../components/report/OrdersReport')
                    },
                    {
                        path: "physical-results",
                        name: "physical-results",
                        component: () => import('../components/report/PhysicalResults')
                    },
                ],

            },
            {
                path: "admin",
                component: DefaultRouteView,
                children: [
                    {
                        path: '',
                        name: 'admin',
                        component: () => import('../views/admin/Index')
                    },
                    {
                        path: 'user-password',
                        name: 'user-password',
                        component: () => import('../components/admin/UserPassword')
                    },
                    {
                        path: 'pase-medico',
                        name: 'pase-medico',
                        component: () => import('../components/admin/PaseMedicoFiles')
                    }
                ]
            },
            {
                path: 'perfil',
                name: 'profile',
                component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
            },
            {
                path: 'medico',
                component: DefaultRouteView,
                children:[
                    {
                        path:'',
                        name:'medical',
                        component: () => import(/* webpackChunkName: "medical-show" */ '../views/medical/Index.vue')
                    },
                    {
                        path:'crear',
                        name:'medical-create',
                        component: () =>import(/* webpackChunkName: "medical-show" */ '../views/medical/Create.vue')
                    },
                    {

                        path: ':code',
                        name: 'medical-show',
                        props: true,
                        component: () => import(/* webpackChunkName: "medical-show" */ '../views/medical/Show.vue'),

                    },
                ]
            },
            {
                path: 'corte-de-caja',
                component: DefaultRouteView,
                children:[
                    {
                        path:'',
                        name:'cash-close',
                        component: () => import(/* webpackChunkName: "cash-close" */ '../views/cash-close/Index.vue')
                    },
                    {
                        path:'create',
                        name:'cash-close-create',
                        component: () => import(/* webpackChunkName: "cash-close" */ '../views/cash-close/Create.vue')
                    },
                ]
            },
            {
                path: 'order-capture',
                name: 'order-capture',
                props: true,
                component: () => import(/* webpackChunkName: "create-order" */ '../views/order/Capture.vue'),
            },
            {

                path: 'captura-de-orden',
                name: 'order-pos',
                props: true,
                component: () => import(/* webpackChunkName: "create-order" */ '../views/order/Pos.vue'),

            },
            {
                path: 'order',
                component: DefaultRouteView,
                children: [
                    {

                        path: '',
                        name: 'order',
                        component: () => import(/* webpackChunkName: "order" */ '../views/order/Index.vue'),
                    },
                    {

                        path: 'create',
                        name: 'order-create',
                        props: true,
                        component: () => import(/* webpackChunkName: "create-order" */ '../views/order/Create.vue'),

                    },
                    {

                        path: ':id',
                        name: 'order-show',
                        props: true,
                        component: () => import(/* webpackChunkName: "show-order" */ '../views/order/Show.vue'),

                    },
                    {

                        path: ':id/billing',
                        name: 'order-billed',
                        props: true,
                        component: () => import(/* webpackChunkName: "show-order" */ '../views/order/Billing.vue'),

                    },
                ]
            },
            {
                path: 'result',
                component: DefaultRouteView,
                children: [
                    {

                        path: '',
                        name: 'result',
                        component: () => import(/* webpackChunkName: "study" */ '../views/result/Index.vue'),
                    },
                ]
            },
            {
                path: 'study',
                component: DefaultRouteView,
                children: [
                    {

                        path: '',
                        name: 'study',
                        component: () => import(/* webpackChunkName: "study" */ '../views/study/Index.vue'),
                    },
                    {

                        path: 'create',
                        name: 'study-create',
                        component: () => import(/* webpackChunkName: "study-create" */ '../views/study/Create.vue'),
                    },
                    {

                        path: ':id',
                        name: 'study-show',
                        props: true,
                        component: () => import(/* webpackChunkName: "study-show" */ '../views/study/Show.vue'),
                    }
                ]
            },
            {
                path: 'patient',
                component: DefaultRouteView,
                children: [
                    {
                        path: '',
                        name: 'patient',
                        component: () => import(/* webpackChunkName: "patient" */ '../views/patient/Index.vue'),
                    },
                    {
                        path: 'create',
                        name: 'patient-create',
                        props: true,
                        component: () => import(/* webpackChunkName: "patient" */ '../views/patient/Create.vue'),
                    },
                    {
                        path: ':id',
                        name: 'patient-show',
                        props: true,
                        component: () => import(/* webpackChunkName: "patient" */ '../views/patient/Show.vue'),
                    },
                ]
            },
            {
                path: '/estadisticas',
                name: 'stats',
                props: true,
                component: () => import(/* webpackChunkName: "create-order" */ '../views/Stats.vue'),
            },
            {
                path: '/io-muestras',
                props: true,
                component: DefaultRouteView,
                children: [
                    {
                        path: '',
                        name: 'io-samples',
                        component: () => import(/* webpackChunkName: "create-order" */ '../views/io-sample/Index.vue'),
                    },
                    {
                        path: ':id',
                        props: true,
                        name: 'ShowLot',
                        component: () => import('../views/io-sample/Show'),
                    },
                    {
                        path: 'create/:type',
                        props: true,
                        name: 'CreateLot',
                        component: () => import('../views/io-sample/Create'),
                    }
                ]
            },
            {
                path: 'invoice',
                component: DefaultRouteView,
                children: [
                    {
                        path: '',
                        name: 'invoice',
                        component: () => import('../views/invoice/Index.vue')
                    }
                ]
            },
            {
                path: 'quotation',
                component: DefaultRouteView,
                children: [
                    {
                        path: '',
                        name: 'quotation',
                        component: () => import('../views/quotation/Index.vue')
                    }
                ]
            },
            {
                path: 'captura-de-resultados',
                component: DefaultRouteView,
                children: [
                    {
                        path: '',
                        name: 'capture',
                        component: () => import('../views/result/Capture.vue')
                    },
                    {
                        path: '/filter',
                        name: 'filter',
                        component: () => import('../views/result/FilterTemporal.vue')
                    }
                ]
            },
            {
                path: 'captura-de-resultados-laboratorio',
                component: DefaultRouteView,
                children: [
                    {
                        path: '',
                        name: 'captureFull',
                        component: () => import('../views/result/CaptureFull.vue')
                    }
                ]
            },
            {
                path: 'agendar-cita',
                component: DefaultRouteView,
                children: [
                    {
                        path: '',
                        name: 'schedule',
                        component: () => import('../views/schedule/Index.vue')
                    }
                ]
            },
            {
                path: 'appointment',
                component: DefaultRouteView,
                children: [
                    {
                        path: '',
                        name: 'appointment',
                        component: () => import('../views/appointment/Index.vue')
                    }
                ]
            },
            {
                path: 'foreign-work',
                component: DefaultRouteView,
                children: [
                    {
                        path: 'foreign-work',
                        name: 'foreign-work',
                        props: true,
                        component: () => import('../views/foreign-work/Index2.vue')
                    }
                ]
            },
            {
                path: 'medical-specialties',
                component: DefaultRouteView,
                children: [
                    {
                        path: 'medical-specialties',
                        name:'medical-specialties',
                        props: true,
                        component: () => import('../views/medical-specialties/Index.vue')
                    }
                ]
            },
            {
                path: 'sales-call-center',
                component: DefaultRouteView,
                children: [
                    {
                        path: '',
                        name: 'sales-call-center',
                        props: true,
                        component: () => import('../views/sales-call-center/Index.vue')
                    }
                ]
            },
            {
                path: 'orders-by-foreign-work',
                component: DefaultRouteView,
                children: [
                    {
                        path: '',
                        name: 'orders-by-foreign-work',
                        props: true,
                        component: () => import('../views/orders-by-foreign-work/Index.vue')
                    }
                ]
            }

        ]
    },
    {
        path: '/auth',
        component: () => import(/* webpackChunkName: "auth-layout" */ '../components/auth/AuthLayout.vue'),
        meta: {
            auth: false,
        },
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue')
            }
        ]
    },
    {
        path: '/403',
        name: 'not-allowed',
        component: () => import(/* webpackChunkName: "not-allowed" */ '../views/errors/NotAllowed')
    },
    {
        path: '/404',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "not-found" */ '../views/errors/NotFound')
    },
    {
        path: '/500',
        name: 'server-error',
        component: () => import(/* webpackChunkName: "server-error" */ '../views/errors/ServerError')
    },
    {
        path: '*',
        name: 'all-pages',
        component: () => import(/* webpackChunkName: "all-pages" */ '../views/errors/NotFound')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'is-active',
    routes
});

export default router
