import Vue from 'vue'
import Vuex from 'vuex'
import utils from "./modules/utils";
import menu from "./modules/menu";
import notification from "./modules/notification";
import order from "./modules/order";
import medical from "./modules/medical";
import account from "./modules/account";
import main from "./modules/main";
import pos from "./modules/pos";
import reports from "./modules/reports";
import admin from "./modules/admin";
import quotation from "@/store/modules/quotation";
import capture from "@/store/modules/capture";
import captureFull from "@/store/modules/captureFull";
import medicalspecialties from "@/store/modules/medicalspecialties";
import salescallcenter from "@/store/modules/salescallcenter";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    utils, menu, notification, order, medical, account, main, pos, reports, admin, quotation, capture, captureFull, medicalspecialties, salescallcenter
  }
});
